<template>
    <el-dialog
            title="功能权限"
            :close-on-click-modal="false"
            width="350px"
            :visible.sync="visible" class="dialogForm">
        <el-checkbox class="m_b2" v-model="checkStrictly"
                     @change="handleCheckedTreeConnect">父子级联动</el-checkbox>
        <el-form size="small" :model="inputForm" status-icon v-loading="loading" ref="inputForm"
                 @keyup.enter.native="inputFormSubmit()"
                 @submit.native.prevent>
            <el-tree
                    :data="menuList"
                    :props="defaultProps"
                    node-key="id"
                    ref="menuListTree"
                    show-checkbox :check-strictly="!checkStrictly">
            </el-tree>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button v-if="!loading" type="primary" size="small" @click="inputFormSubmit()"
                       v-noMoreClick>保存
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                title: '',
                menuList: [],
                menuCheckedKeys: [],
                dataRuleCheckedKeys: [],
                inputForm: {
                    id: '',
                    menuIds: '',
                },
                defaultProps: {
                    label: 'name',
                    children: 'children',
                },
                pageType: '',
                checkStrictly: false,
            }
        },
        methods: {
            // type  temporary临时权限
            init(id, type) {
                this.checkStrictly = false
                this.pageType = type
                this.inputForm.id = id
                this.visible = true
                this.$nextTick(() => {
                    this.$refs.inputForm.resetFields()
                    this.$refs.menuListTree.setCheckedKeys([])
                    let p1 = this.$axios(this.api.auth.findAllMenu, {}, 'get')
                    if (this.inputForm.id) {
                        this.loading = true
                        let api = this.api.auth.getById,
                            p = {
                                roleId: this.inputForm.id
                            }
                        if (this.pageType == 'temporary') {
                            api = this.api.auth.sysrolemenutempGetById
                            p = {
                                authorityId: this.inputForm.id,
                            }
                        }
                        let p2 = this.$axios(api, p, 'get')
                        Promise.all([p1, p2]).then((result) => {
                            this.menuList = result[0].data
                            let data = result[1]
                            if (data.status) {
                                this.loading = false
                                let that = this
                                setTimeout(function () {
                                    var menuIds = data.data.menuIds.split(',')
                                    menuIds.forEach(value => {
                                        that.$refs.menuListTree.setChecked(value, true, false)
                                    })
                                })
                            }
                        })
                    } else {
                        Promise.all([p1]).then((result) => {
                            this.menuList = result[0].data.treeData
                            this.loading = false
                        })
                    }
                })
            },
            handleCheckedTreeConnect(val) {
                this.checkStrictly = val
            },
            // 表单提交
            inputFormSubmit() {
                this.inputForm.menuIds = this.$refs.menuListTree.getCheckedKeys().concat(this.$refs.menuListTree.getHalfCheckedKeys()).join(',')
                this.loading = true
                let api = this.api.auth.sysrolemenuSave,
                    p = {
                        roleId: this.inputForm.id
                    }
                if (this.pageType == 'temporary') {
                    api = this.api.auth.sysrolemenutempSave
                    p = {
                        sysTemporaryAuthorityId: this.inputForm.id,
                    }
                }
                this.$axios(api, {...p, menuIds: this.inputForm.menuIds}, 'post').then(data => {
                    this.loading = false
                    if (data.status) {
                        this.$message.success(data.msg)
                        this.visible = false
                        this.$emit('refreshDataList')
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
